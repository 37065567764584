import React from "react"
import PropTypes from "prop-types"

import "../assets/sass/switcher.scss"

const Switcher = ({ className, handleToggleFunc, name, state }) => {
  return (
    <label className={`switcher ${className}`} htmlFor={name}>
      <input
        checked={state}
        className="form-check-input"
        id={name}
        onChange={event => handleToggleFunc(event.currentTarget.checked)}
        type="checkbox"
      />
      <span className="slider" />
    </label>
  )
}

Switcher.propTypes = {
  className: PropTypes.string,
  handleToggleFunc: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.bool.isRequired,
}

Switcher.defaultProps = {
  className: "",
}

export default Switcher
