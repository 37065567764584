import React from "react"
import { useTranslation } from "react-i18next"

import "../assets/sass/page.scss"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ShortOffers from "../components/ShortOffers"

const Offers = () => {
  const { t } = useTranslation()
  const title = t("Our offers")

  return (
    <Layout
      hasHeader={true}
      subtitle={t("A free version, solo or team")}
      title={title}
    >
      <Seo title={title} />
      <div className="container page-content-top">
        <h2 className="bold-40 text-center mb-2">
          {t("Choose the right offer for you")}
        </h2>
        <p className="light-24 text-center lh-2">
          {t("Not sure which package is right for you?")}
        </p>
        <p className="regular-24 text-center lh-2">
          <span className="bold-24">{t("4 possibilities:")}</span>{" "}
          {t("Free, Solo, Team and Organisation")}
        </p>
        <div className="mt-4">
          <ShortOffers />
        </div>
      </div>
    </Layout>
  )
}

export default Offers
