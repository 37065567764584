import React, { useState } from "react"

import PaymentFrequency from "./PaymentFrequency"

const ShortOffers = () => {
  // Payment Frequency State : monthly = 1, annual = 2
  const [paymentFrequencyState, setPaymentFrequencyState] = useState(2)

  return (
    <div>
      <PaymentFrequency
        name="short-offers"
        paymentFrequencyState={paymentFrequencyState}
        setPaymentFrequencyState={setPaymentFrequencyState}
      />
    </div>
  )
}

export default ShortOffers
