import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import Switcher from "./Switcher"
import "../assets/sass/payment_frequency.scss"

const PaymentFrequency = ({
  name,
  paymentFrequencyState,
  setPaymentFrequencyState,
}) => {
  const { t } = useTranslation()

  const handleToggleSwitch = checked => {
    if (checked) {
      setPaymentFrequencyState(2)
    } else {
      setPaymentFrequencyState(1)
    }
  }

  return (
    <div className="payment-frequency">
      <p
        className={
          paymentFrequencyState === 1 ? "active regular-16" : "light-16"
        }
      >
        {t("Monthly payment")}
      </p>
      <Switcher
        className="payment-frequency-switcher"
        handleToggleFunc={handleToggleSwitch}
        name={name}
        state={paymentFrequencyState === 2}
      />
      <p
        className={
          paymentFrequencyState === 2 ? "active regular-16" : "light-16"
        }
      >
        {t("Annual payment")}{" "}
        <span className="bold-16">{t("Save 10% off")}</span>
      </p>
    </div>
  )
}

PaymentFrequency.propTypes = {
  name: PropTypes.string.isRequired,
  paymentFrequencyState: PropTypes.number.isRequired,
  setPaymentFrequencyState: PropTypes.func.isRequired,
}

export default PaymentFrequency
